import React from 'react';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../../../components/shared/layout';
import SEO from '../../../components/SEO/SEO';
import styled from '@emotion/styled';
import { wrapper, wrapper1200, breakpoints, noFeaturedWrapper, color } from '../../../utils/style';
import ContentWithFluidImages from '../../../components/shared/ContentWithFluidImages';
import Breadcrumbs from '../../../components/shared/Breadcrumbs';
import IcomoonIcon from '../../../components/shared/IcomoonIcon';
import Img from 'gatsby-image';

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const Wrapper = styled('div')`
    ${wrapper}
    margin: 0 auto 30px;
    p {
      margin-bottom: 1rem;
    }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}
  h1 {
    margin-bottom: 10px;
  }
`;

const OtherGamesTitle = styled('div')`
  text-align: center;
  margin-bottom: 30px;
  span {
    color: ${color.textGrey}; 
  }
  svg {
    margin: 0 10px;
  }
`;

const GameAds = styled('ul')`
   max-width: 100%;
   overflow-x: auto;
   white-space: nowrap;
   list-style: none;
`;

const GameAd = styled('li')`
  width: 23%;
  display: inline-block;
  vertical-align: top;
  margin: 0.66%;
  &:first-of-type {
    margin-left: 0;
  }
  a {
    display: block;
    border-radius: 24px;
    border: 2px solid transparent;
    &:hover, &:active {
      border-color: ${color.yellow};
    }
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  
  @media (max-width: ${breakpoints.sm}) {
    width: 30%;
    margin: 10px 2% 10px 0;
  }
`;

const PostTemplate = ({ data, pageContext }) => {


  const post = data.wordpressWpDemo;
  const linkGame = post.acf.link_game;

  const metaTitle = (post.yoast_meta && post.yoast_meta.yoast_wpseo_title)
    ? post.yoast_meta.yoast_wpseo_title : post.title + +"- " + process.env.OWNER;
  const metaDesc = (post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc)
    ? post.yoast_meta.yoast_wpseo_metadesc : '';
  const media = data.allWordpressWpMedia.edges;
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const otherGames = data.allWordpressWpDemo.edges;

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        <NoFeaturedWrapper key="no-featured-wrapper">
          <h1 dangerouslySetInnerHTML={{ __html: post.title }}/>
        </NoFeaturedWrapper>

        <Wrapper>
          <div className="iframe-container">
            <iframe src={linkGame}/>
          </div>
          {process.env.WP_REPLACE_CONTENT_IMAGES === '1' ? ContentWithFluidImages(post.content, media) :
            <div dangerouslySetInnerHTML={{ __html: post.content }}/>}
        </Wrapper>
        <OtherGamesTitle>
          <IcomoonIcon icon="arrow-down-simple" color={color.textGrey} size={16}/>
          <span>other interesting games</span>
          <IcomoonIcon icon="arrow-down-simple" color={color.textGrey} size={16}/>
        </OtherGamesTitle>
        <GameAds>
          {otherGames.map((item) =>
            <GameAd key={item.node.title}>
              <Link to={item.node.path}>
                {item.node.featured_media && item.node.featured_media.localFile &&
                item.node.featured_media.localFile.childImageSharp && item.node.featured_media.alt_text &&
                <Img fluid={item.node.featured_media.localFile.childImageSharp.fluid}
                     alt={item.node.title}/>
                }

              </Link>
            </GameAd>
          )}</GameAds>
      </WrapperDesktop>
    </Layout>
  );
};

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!, $contentImages: [Int]) {
    wordpressWpDemo(id: { eq: $id }) {
      title
      content
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        link_game
      }
    }
    
    allWordpressWpDemo(filter: {id: {ne: $id}}, limit: 6) {
     edges {
       node {
         wordpress_id
         title
         path
         date(formatString: "MMMM DD, YYYY")
         featured_media {
           alt_text
           localFile {
             childImageSharp {
               fluid(maxWidth: 430) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }

       }
     }
   }

    placeholder: file(relativePath: { eq: "placeholder.png" }) {
      childImageSharp {
        fluid(maxWidth: 430) {
           ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allWordpressWpMedia(filter: {wordpress_id: {in: $contentImages}}) {
      edges {
        node {
          wordpress_id
          alt_text  
          source_url
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1980) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
        }
      }
    }
  }
`;
